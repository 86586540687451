type ExposeAppConfigProps = {
  appConfig: AppConfig;
};

export default function ExposeAppConfig({ appConfig }: ExposeAppConfigProps) {
  return (
    <script
      id={'app-config'}
      suppressHydrationWarning
      dangerouslySetInnerHTML={{
        __html: `window.appConfig = ${JSON.stringify(appConfig)}`, //typed in the ../types/global.d.ts
      }}
    />
  );
}
